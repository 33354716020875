// Components
import Link from "@/components/ui/Link/Link";

// Routes
import { ROUTES } from "@/routes/routes";

const Navigation = () => {
  return (
    <nav>
      <ul>
        <li>
          <Link isNavLink to={ROUTES.homePage.path}>
            Home
          </Link>
        </li>
        <li>
          <Link isNavLink to={ROUTES.account.signup.path}>
            Sign Up
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
