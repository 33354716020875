import { configureStore } from "@reduxjs/toolkit";

// Slices
import mainReducer from "@/store/slices/mainSlice";

export const store = configureStore({
  reducer: {
    main: mainReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["user/setUserData"],
        ignoredPaths: [],
      },
    }),
});
