import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { HelmetProvider } from "react-helmet-async";

// Components
import App from "@/App";

// Store
import { store } from "@/store";

// Sentry
import "@/sentry.config";

// Config
import { GOOGLE_CLIENT_ID } from "@/config";

// Styles
import "@/styles/index.scss";
import "@/styles/reset.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </GoogleOAuthProvider>
    </Provider>
  </React.StrictMode>,
);
