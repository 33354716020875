import { NavLink, Link as RouterLink } from "react-router-dom";

const Link = ({ to, children, isNavLink, className }) => {
  const LinkComponent = isNavLink ? NavLink : RouterLink;

  return (
    <LinkComponent to={to} className={className}>
      {children}
    </LinkComponent>
  );
};

export default Link;
