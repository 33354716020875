import { lazy } from "react";

export const LazyPage404 = lazy(() => import("@/pages/404/Page404"));

export const LazyHomePage = lazy(() => import("@/pages/HomePage/HomePage"));

// User account
export const LazyAccountPage = lazy(() => import("@/pages/account/AccountPage/AccountPage"));
export const LazySignUpPage = lazy(() => import("@/pages/account/SignUpPage/SignUpPage"));
export const LazyVerifyEmailPage = lazy(
  () => import("@/pages/account/VerifyEmailPage/VerifyEmailPage"),
);
export const LazyResetPasswordKeyPage = lazy(
  () => import("@/pages/account/ResetPasswordKeyPage/ResetPasswordKeyPage"),
);
