export const ENV = process.env.ENV;
export const IS_DEBUG_MODE_ENABLED = process.env.IS_DEBUG_MODE_ENABLED === "true";

export const API_URL = process.env.API_URL;
export const API_URL_ALLAUTH = process.env.API_URL + "/_allauth/app/v1";

export const SENTRY_DSN = process.env.SENTRY_DSN;

export const GOOGLE_CLIENT_ID = process.env.GOOGLE_CLIENT_ID;
export const CLIENT_ID = process.env.CLIENT_ID;
