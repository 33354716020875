export const ROUTES = {
  homePage: {
    path: "/",
  },
  account: {
    path: "/account",
    signup: {
      path: "/account/signup",
    },
    verifyEmail: {
      path: "/account/verify-email/:key",
    },
    resetPasswordKey: {
      path: "/account/password/reset/key/:key",
    },
  },
};
