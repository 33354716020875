import { createBrowserRouter } from "react-router-dom";
import { Suspense } from "react";

// Components
import Layout from "@/components/Layout/Layout";
import GlobalLoader from "@/components/GlobalLoader/GlobalLoader";

// Pages
import { ROUTES } from "@/routes/routes";
import {
  LazyAccountPage,
  LazyHomePage,
  LazyPage404,
  LazyResetPasswordKeyPage,
  LazySignUpPage,
  LazyVerifyEmailPage,
} from "@/pages/LazyPages";

const getPage = LazyPage => (
  <Suspense fallback={<GlobalLoader />}>
    <LazyPage />
  </Suspense>
);

export const router = createBrowserRouter([
  {
    element: <Layout />,
    errorElement: <LazyPage404 />,
    children: [
      {
        path: ROUTES.homePage.path,
        element: getPage(LazyHomePage),
      },
      // User account
      {
        path: ROUTES.account.path,
        element: getPage(LazyAccountPage),
      },
      {
        path: ROUTES.account.signup.path,
        element: getPage(LazySignUpPage),
      },
      {
        path: ROUTES.account.verifyEmail.path,
        element: getPage(LazyVerifyEmailPage),
      },
      {
        path: ROUTES.account.resetPasswordKey.path,
        element: getPage(LazyResetPasswordKeyPage),
      },
    ],
  },
]);
