import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isMobile: false,
  isIOS: false,
  loader: {
    isLoading: false,
    loadingText: "",
  },
};

export const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    setMobile: (state, action) => {
      state.isMobile = action.payload;
    },
    setIsIOS: (state, action) => {
      state.isIOS = action.payload;
    },
    setLoaderData: (state, action) => {
      state.loader.isLoading = action.payload.isLoading ?? state.loader.isLoading;
      state.loader.loadingText = action.payload.loadingText || "";
    },
  },
});

export const { setMobile, setIsIOS, setLoaderData } = mainSlice.actions;

export default mainSlice.reducer;
