import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import uaParserJs from "ua-parser-js";

// Components
import Portal from "@/components/Portal/Portal";
import GlobalLoader from "@/components/GlobalLoader/GlobalLoader";

// Hooks
import { useAppDispatch, useAppSelector } from "@/hooks/store";

// Routes
import { router } from "@/routes";

// Store
import { setIsIOS } from "@/store/slices/mainSlice";

const App = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(s => s.main.loader.isLoading);

  useEffect(() => {
    const userAgent = uaParserJs(window.navigator.userAgent);
    const isIOS = userAgent?.os.name === "iOS";
    dispatch(setIsIOS(isIOS));
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Tektur:wght@400;500&display=swap"
          rel="stylesheet"
        />
        <meta name="theme-color" content="#000000" />
        <meta name="description" content="Art of smile" />
      </Helmet>
      <RouterProvider router={router} />
      {isLoading && (
        <Portal>
          <GlobalLoader />
        </Portal>
      )}
    </>
  );
};

export default App;
