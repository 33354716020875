// Components
import Loader from "@/components/Loader/Loader";

// Hooks
import { useAppSelector } from "@/hooks/store";

// Styles
import styles from "./GlobalLoader.module.scss";

const GlobalLoader = () => {
  const { loadingText } = useAppSelector(s => s.main.loader);

  return (
    <div className={styles.loaderWrapper}>
      {loadingText && <p className={styles.statusText}>{loadingText}...</p>}
      <Loader />
    </div>
  );
};

export default GlobalLoader;
